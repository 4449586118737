import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    flex-direction: row;
  }
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    justify-content: space-between;
  }
`

const FlexContainer = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default FlexContainer
