import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'
import * as variables from '../utilities/style-variables.js';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 0 20px 30px 0;
  padding: 10px 20px;
  border-left: 3px solid ${variables.black};

  /* &::after {
    content: "";
    display: inline-block;
    width: 0px;
    background-color: ${variables.brand_primary};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    z-index: -1;
    transition: all ease-in-out 300ms;
  }

  &:hover {
    cursor: pointer;

    &::after {
      width: 100%;
      transition: all ease-in-out 300ms;
    }
  } */

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    width: 380px;
    margin-bottom: 50px;
  }
  
`

const ImgWrapper = styled.figure`
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
`

const ContentWrapper = styled.div`
  display: block;
  width: 250px;
`

const PlattformName = styled.div`
  font-weight: ${variables.font_semibold};
  font-size: ${variables.font_size__mobile_text};

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_text}
  }
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_text}
  }
`

const ItemDescription = styled.div`
  font-weight: ${variables.font_regular};
  font-size: ${variables.font_size__mobile_text};

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_text}
  }
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_text}
  }
`

const PortfolioItem = () => {

  const data = useStaticQuery(
    graphql`
      query MyPortfolio {
        allPortfolioJson {
          edges {
            node {
              id
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 50
                  )
                }
              }
              name
              nameId
              description
              sort_number
            }
          }
        }
      }
    `
  )

  return (
    <>
      {data.allPortfolioJson.edges.map((portfolio) => {

        const id = portfolio.node.id
        const name = portfolio.node.name
        const description = portfolio.node.description
        const portfolioImage = portfolio.node.image
        const nameId = portfolio.node.nameId

        const image = getImage(portfolioImage)

        return(
          <Wrapper key={id} id={nameId}>
            <ImgWrapper>
              <GatsbyImage 
                image= {image}
                width={30}
                alt={name}
                title={name}
              />
            </ImgWrapper>
            <ContentWrapper>
              <PlattformName>{name}</PlattformName>
              <ItemDescription>{description}</ItemDescription>
            </ContentWrapper>
          </Wrapper>
        )
      })}
    </>
  )
}

export default PortfolioItem
