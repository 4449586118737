import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Btn from "../components/button"
import List from "../components/list"
import Section from "../components/section"
import Card from "../components/card"
import ContactFormSection from "../components/contactFormSection"
import PortfolioItem from "../components/portfolioItem"
import FlexContainer from "../components/flexContainer"
import strategyIcon from '../images/icons/set/icon-choice.png'
import implementationIcon from '../images/icons/set/icon-rocket.png'
import analysisIcon from '../images/icons/set/icon-medal.png' 
import HeroBg from "../components/heroBg"
import bgImage from "../images/backgrounds/hero-online-marketing.png"
import { Link } from "gatsby"

const OnlineMarketingPage = ({ location }) => (
  
  <>
  {/* Hero section  */}
  <Layout
    breadcrumb={
      {"Online Marketing": "/online-marketing"}
    }
  >
    <Seo 
      title="Online Marketing verständlich umgesetzt | cliqit"
      description="Egal ob Markenbekanntheit oder Produktverkauf. Mit unseren Online Marketing Maßnahmen klicken Nutzer auf deine Anzeigen."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section noSeparator>
      <div className="row">
        <div className="col-md-8">
          <h1 >Online Marketing</h1>
          <p className="subheading align-left">Egal ob Markenbekanntheit oder Produktverkauf. Mit unseren Online Marketing Maßnahmen klicken Nutzer auf deine Anzeigen.</p>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <List 
            columnCount={2}
            listItems={[
              { text:"Google Ads", href:"#plattformen", key:"1" },
              { text:"Microsoft Advertising / Bing", href:"#plattformen", key:"2" },
              { text:"Display Marketing", href:"#plattformen", key:"3" },
              { text:"Spotify Ad Studio", href:"#plattformen", key:"4" },
              { text:"Facebook Ads & Instagram", href:"#plattformen", key:"5" },
              { text:"TikTok Ads", href:"#plattformen", key:"6" },
              { text:"LinkedIn Ads", href:"#plattformen", key:"7" },
              { text:"Twitter Ads", href:"#plattformen", key:"8" },
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column flex-md-row justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Kontakt aufnehmen"
            href="#contact-form" 
          />
          <Btn
            type="secondary"
            text="Erzähl mir mehr"
            href="#planung-umsetzung-analyse"
          />
        </div>
      </div>
    </Section>
    <HeroBg 
      bgImage={bgImage}
    />

    <Section bgGradient spikes>
      <div className="row" id="planung-umsetzung-analyse">
        <div className="col">
          <div className="headings">
            <h2>Planung. Umsetzung. Analyse.</h2>
            <p className="subheading">Entfalte das volle Potenzial deiner online Werbemaßnahmen.</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <Card 
            icon={strategyIcon}
            heading="Strategiekonzept"
            text="Ziele erreicht man nur mit der passenden Strategie. Damit du auch wirklich deine Markenbekanntheit erhöhst oder den Absatz deiner Produkte steigerst, erarbeiten wir die für dich passende Strategie zusammen."      
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <Card 
            icon={implementationIcon}
            heading="Durchführung"
            text="Wir realisieren deine Kampagnenideen, richten die Werbekonten ein, verwalten dein Werbebudget & begleiten deine Kampagne während der gesamten Laufzeit, um das Meiste dabei herauszuholen."
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <Card 
            icon={analysisIcon}
            heading="Erfolgsanalyse"
            text="Keine Erfolgssicherung ohne Analyse. Daher sorgen wir dafür, dass du jeden eingesetzten Euro auf deine Erfolge umrechnen kannst. Treffe deine Entscheidungen datenbasiert."       
          /></div>
      </div>
    </Section>

    <Section>
      <div className="row" id="plattformen">
        <div className="col">
          <h2>Für jeden Zweck die passende Werbeplattform</h2>
          <p className="subheading">Der perfekte Werbemix ist das Salz in der Suppe.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FlexContainer>
            <PortfolioItem />
          </FlexContainer>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <h3>Was sind die Vorteile von Online Marketing?</h3>
          <p>
            Online Marketing Maßnahmen haben im Vergleich zur Werbung über klassiche Medien, wie zum Beispiel TV oder Radio, viele Vorteile.
            <ol>
              <li>Online Kampagnen können über die Business Manager der Werbeplattformen <strong>eigenständig & schnell verwaltet</strong> werden. Eine fest Mediabuchung mehrere Monate im Voraus ist nicht nötig. </li>
              <li>In analogen Medien ist nur schwer zu messen, wer deine Anzeige sieht oder hört. Online ist <strong>die Messbarkeit gegeben</strong>. Du siehst wie viele Menschen du erreicht hast, wie viele mit der Anzeige interagierten und was dich das Ganze kostet.</li>
              <li>Auch <strong>laufende Kampagnen</strong> können weiterhin <strong>angepasst und optimiert werden</strong>. Egal ob Budgeterhöhung oder -senkung, Zielgruppenanpassung oder bei Misserfolg das Pausieren von Kampagnen - alles ist machbar.</li>
              <li>Erreiche nur die Menschen, die du erreichen willst. Der <strong>Streuverlust wird minimiert</strong> und das <strong>Werbebudget effizient eingesetzt</strong>.</li>
              <li><strong>Passe deine Werbekommunikation an die Zielgruppe an</strong>. So erhalten Nutzer, die deine Produkte oder Dienstleistung bereits kennen, andere Anzeigen als neue Interessenten, die das erste mal von dir hören.</li>
            </ol>
          </p>
            
          <h4>Wie bezahle ich für meine Online Werbung?</h4>
          <p>
            Online Werbeanzeigen werden oft nach <strong>Cost-per-Click (CPC) oder nach Impressionen</strong>, also der Anzahl der Ausspielungen der Werbeanzeigen, <strong>abgerechnet</strong>.<br/><br/>
            Bei CPC Abrechnung zahlst du nur, <strong>wenn Nutzer auf deine Anzeigen klicken</strong>. Das bedeutet, dass das reine Betrachten der Anzeige durch den Nutzer für dich nichts kostet. Diese Abrechnungsart wird meist in Suchmaschinen wie Google und Bing verwendet, um Anzeigen auszuspielen, wenn Nutzer nach etwas bestimmten suchen.<br/><br/>
            Bei Kampagnen, deren Ziel es ist die Markenbekanntheit zu steigern oder möglichst vielen Menschen die Werbung anzuzeigen, wird zumeinst nach Impressionen abgrechnet. Somit zahlt man <strong>bei jeder einzelnen Ausspielung der Anzeige</strong> für die erhaltene Werbeleistung. 
          </p>

          <h4>Ist Online Werbung datenschutzkonform?</h4>
          <p>
            Auch wenn DSGVO und der damit stärker in den Fokus gerückte Datenschutz personenbezogener Daten die Ausführung von online Werbemaßnahmen stark beeinflusste, ist das Schalten von Werbeanzeigen über Facebook, Google und Co. weiterhin <strong>unter Berücksichtigung gewisster Datenschutzaspekte möglich</strong>.<br/><br/>
            Nutzt man nur die Daten des Werbenetzwerkes, z.B. die Interessen bei Instagram, so gibt es nicht viel zu beachten. Möchte man jedoch auf der eigenen Website <strong>eine Erfolgsmessung durchführen</strong> oder Nutzer, die die eigene Seite besucht haben auf Sozialen Netzwerken erneut ansprechen, so ist der <Link to="/webtracking">Einbau von Tracking-Code</Link> der Werbeplattform nötig. 
            Hierdurch können die Werbeplattformen Nutzer verfolgen. Daher solltst du deine <strong>Datenschutzerklärung anpassen</strong>, sowie durch die <strong>Erlaubnisabfrage zur Nachverfolgung</strong> über den Cookie Banner einholen.
          </p>

          <h4>Wie viel Geld muss ich mindestens investieren?</h4>
          <p>
            Dies Frage kann pauschal nicht beantwortet werden und ist von schiedenen Faktoren abhänggig.
            <ol>
              <li><b>Welche Werbeplattform wird verwendet?</b> Die meisten Werbeplattformen haben keine Mindestbudgetbeschränkung. Jedoch gibt es einzelne Ausnahmen, wie z.B. Spotify Ad Studio, wo man erst ab 250€ Werbung schalten kann.</li>
              <li><b>Wie lange möchtest du Werbung schalten?</b> Je länger du Werbung schalten möchtest, desto mehr Budget benötigst du. Ansonsten wird dein Werbebudget auf die Anzahl der Tage verteilt, was pro Tag ein geringes Budget und somit einen geringeren Werbedruck zur Folge hat.</li>
              <li><b>Was ist dir dein Ziel wert?</b> Möchtest du beispielsweise 100 neue Kontakte generieren und ist dir ein Kontakt maximal 30€ wert, dann solltest du mit mindestens 3.000€ planen.</li>
              <li><b>Wer ist der richtige Nutzer?</b> Die Marketingplattformen versuchen durch smarte Algorithmen genau die Nutzer anzusprechen, für die deine Anzeige relevant ist. Du benötigst also genug Budget, dass das Ausspielen der Anzeige an verschiedenen Nutzern getestet werden kann. Dadurch erzielst du langfristig günstigere Ergebnisse.</li>
            </ol>
            Bei der genauen Ermittlung des Werbebudgets helfen wir dir selbstverständlich gerne.
          </p>

          <h4>Wie lange dauert es, bis meine Anzeigen online sind?</h4>
          <p>
            In der Regel durchlaufen die Anzeigen nach Erstellung einen Freigabeprozess. Dabei wird die Werbeanzeige auf diverse Verstöß, z.B. diskriminiernde Äußerungen, untersucht. Dieser Prozess läuft meist automatisch und <strong>Anzeigen sind innerhalb einer Stunde online</strong>. <strong>In Einzelfällen</strong> wird die Anzeige von einem Mitarbeiter der Werbeplattform gesichtet, was <strong>ca. einen Tag dauern</strong> kann.
          </p>

          <h4>Welche Plattform ist die richtige für mich?</h4>
          <p>
            Die ständig wachsende Anzahl an <strong>Social Media Netzwerken und Suchmaschinen</strong> bietet es Werbetreibende immer mehr Möglichkeiten Nutzer <strong>die eigenen Produkte, Marke und Dienstleistung vorzustellen</strong>. Dabei kann man die Online Werbekampagnen in zwei große Kategorien unterteilen: Bei Push-Werbekanälen werden die Anzeigen an Nutzer 
            basierend auf deren Verhalten, Interessen und demografischen Merkmalen ausgespielt, was stark in Social Media Netzwerken wie Facebook, Instagram, Tiktok und co. zum Einsatz kommt.<br/><br/>
            Beim zweiten Ansatz, dem sogenannten Pull-Marketing, versucht man Interessenten, die bereits auf der Suche nach der eigenen Produkte oder Dienstleistung sind
            zu sich zu ziehen. Diese Werbestrategie wird hauptsächlich in <strong>Suchmaschinenwerbung (oft als SEA abgekürzt)</strong> angewandt.
          </p>
        </div>
      </div>
    </Section>

    <ContactFormSection 
      heading="Erzähle uns von deinen Ideen"
      text="Schicke uns eine Nachricht mit deinem Anliegen oder deinen Fragen."
    />
    
  </Layout>
  </>
)
export default OnlineMarketingPage
