import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import * as variables from '../utilities/style-variables.js'

const Wrapper = styled.div`
  margin: 0 auto 100px;
  padding: 60px 40px 40px 40px;
  max-width: 450px;
  background-color: ${variables.white};
  transform: skew(0deg, 5deg);

  &:first-child{
      margin-top: 80px;

      @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
          margin-top: 50px;
      } 
  }
`

const Icon = styled.div`
  width: 100px;
  height: 100px;
  margin: -100px auto 20px;
  padding: 15px;
  transform: skew(0deg, -5deg);
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
`

const Heading = styled.h3 `
  margin-bottom: 20px;
  font-size: ${variables.font_size__mobile_small};
  text-align: left;
  transform: skew(0deg, -5deg);

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_small};
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_small};
  }
`

const Text = styled.p`
  font-size: ${variables.font_size__mobile_text};
  transform: skew(0deg, -5deg);

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_text};
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_text};
  }
`

const Card = ({ icon, heading, text }) => {

  const bgIcon = icon;

  return (
    <>
      <Wrapper>
        <Icon
          style={{backgroundImage: `url(${bgIcon})`}}
        >
        </Icon>
        <Heading>{heading}</Heading>
        <Text>{text}</Text>
      </Wrapper>
    </>
  )
}

Card.propTypes = {
  icon: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
}

Card.defaultProps = {
  heading: "",
  text: "",
}

export default Card
